.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  font-family: Arial, sans-serif;
}

.userInfo {
  display: flex;
  align-items: center;
}

.userInfo span {
  margin-right: 10px;
}

.logoutButton, .loginButton {
  padding: 8px 16px;
  font-size: 1em;
  color: #007bff;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.logoutButton:hover, .loginButton:hover {
  background-color: #e6e6e6;
}
