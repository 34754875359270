/* UploadKI.module.css */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.description {
  font-size: 1rem;
  color: #666;
  text-align: center;
  margin-bottom: 40px;
}

.properties {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.property {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.propertyTitle {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.propertyStat {
  font-size: 1rem;
  color: #555;
  margin: 5px 0;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 1rem;
}

.submitButton {
  display: block;
  width: 100%;
  padding: 15px;
  font-size: 1.2rem;
  color: #fff;
  background: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
}

.submitButton:hover {
  background: #0056b3;
}
