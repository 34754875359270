/* Psychedelic.module.css */
.psychedelicContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #ff00cc, #3333ff, #ff99ff, #ffcc00);
  background-size: 600% 600%;
  color: #fff;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  animation: backgroundAnimation 5s infinite alternate ease-in-out;
  overflow: auto;
  position: relative;
  padding: 20px;
  perspective: 1000px;
}

@keyframes backgroundAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.psychedelicTitle {
  font-size: 3em;
  text-transform: uppercase;
  margin-bottom: 1em;
  text-shadow: 3px 3px #ff00ff, -3px -3px #00ffff;
  animation: titleAnimation 10s infinite linear alternate;
  position: absolute;
}

@keyframes titleAnimation {
  0% { transform: translate(0, 0) rotateX(0deg) rotateY(0deg); }
  25% { transform: translate(20px, -20px) rotateX(45deg) rotateY(45deg); }
  50% { transform: translate(-20px, 20px) rotateX(90deg) rotateY(90deg); }
  75% { transform: translate(30px, -30px) rotateX(135deg) rotateY(135deg); }
  100% { transform: translate(-30px, 30px) rotateX(180deg) rotateY(180deg); }
}

.psychedelicErrorMessage {
  color: #ffdddd;
  background: #550000;
  padding: 0.5em;
  border-radius: 10px;
  font-weight: bold;
  animation: errorAnimation 1s infinite alternate ease-in-out;
}

@keyframes errorAnimation {
  0% { transform: scale(1); }
  100% { transform: scale(1.1); }
}

.psychedelicJobContainer {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  animation: containerAnimation 10s infinite alternate ease-in-out;
}

@keyframes containerAnimation {
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
}

.psychedelicJobItem {
  position: absolute;
  background: linear-gradient(45deg, #ff0000, #ff9900, #33cc33, #3399ff, #9933ff);
  padding: 0.5em;
  border-radius: 10px;
  box-shadow: 0 0 15px #000;
  font-size: 1.2em;
  animation: jobItemAnimation 3s infinite alternate ease-in-out, moveChaos 5s infinite alternate ease-in-out;
  transition: transform 0.3s ease, background 0.3s ease;
}

.psychedelicJobItem:hover {
  transform: perspective(500px) rotateY(180deg) scale(1.5) translateZ(50px);
  background: linear-gradient(45deg, #3399ff, #33cc33, #ff9900, #ff0000, #9933ff);
  animation: hoverAnimation 1s infinite alternate ease-in-out, hoverChaos 0.5s infinite alternate ease-in-out, shake 0.5s infinite ease-in-out;
}

@keyframes jobItemAnimation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes hoverAnimation {
  0% { filter: hue-rotate(0deg); }
  100% { filter: hue-rotate(360deg); }
}

@keyframes hoverChaos {
  0% { transform: scale(1.5) rotateY(180deg) skewX(0deg); }
  100% { transform: scale(1.6) rotateY(360deg) skewX(10deg); }
}

@keyframes moveChaos {
  0% { transform: translate(0, 0); }
  25% { transform: translate(20px, -20px) rotate(45deg); }
  50% { transform: translate(-20px, 20px) rotate(-45deg); }
  75% { transform: translate(10px, -10px) rotate(90deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

@keyframes shake {
  0% { transform: translate(0, 0); }
  25% { transform: translate(5px, 5px); }
  50% { transform: translate(-5px, -5px); }
  75% { transform: translate(5px, -5px); }
  100% { transform: translate(0, 0); }
}

.psychedelicRandomElement {
  position: absolute;
  background: radial-gradient(circle, #ff00cc, #3333ff);
  border-radius: 50%;
  animation: randomElementAnimation 3s infinite alternate ease-in-out, moveRandom 5s infinite alternate ease-in-out;
}

@keyframes randomElementAnimation {
  0% { transform: scale(1); }
  50% { transform: scale(3) rotate(180deg); }
  100% { transform: scale(1) rotate(360deg); }
}

@keyframes moveRandom {
  0% { left: 0%; top: 0%; }
  10% { left: 20%; top: 40%; }
  20% { left: 50%; top: 10%; }
  30% { left: 70%; top: 60%; }
  40% { left: 30%; top: 20%; }
  50% { left: 60%; top: 50%; }
  60% { left: 40%; top: 30%; }
  70% { left: 80%; top: 70%; }
  80% { left: 20%; top: 50%; }
  90% { left: 50%; top: 80%; }
  100% { left: 70%; top: 40%; }
}
