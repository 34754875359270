.userProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  max-height: 100vh;
}

.profileHeader {
  text-align: center;
  margin-bottom: 2rem;
}

.profileContent {
  width: 100%;
  max-width: 500px;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.formGroup label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #555;
}

.inputGroup {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: border-color 0.3s;
}

.form-control:focus {
  border-color: #007bff;
  outline: none;
}

.fieldDisplay {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.iconButton {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s;
}

.iconButton:hover {
  color: #0056b3;
}

.profileDetails {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.btn {
  margin-top: 1rem;
}

.btn-link {
  color: #007bff;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
}

.btn-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .profileContent {
    padding: 1.5rem;
  }

  h1 {
    font-size: 1.8rem;
  }

  .form-control {
    font-size: 0.9rem;
  }
}

.passwordChangeContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.buttonGroup {
  display: flex;
  gap: 1rem;
}

.centeredErrorMessage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 400px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  padding: 1rem;
  z-index: 1000;
}