.formContainer {
  max-width: 800px;
  width: 90%;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.formTitle {
  font-size: 28px;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
}

.formGroup {
  margin-bottom: 25px;
}

.formLabel {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #444;
}

.formControl {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.formControl:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.formSelect {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23555' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 12px;
}

.timeInputs {
  display: flex;
  gap: 10px;
}

.timeInput {
  flex: 1;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  font-weight: normal;
}

.checkbox {
  margin-right: 10px;
}

.submitButton {
  display: block;
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.createButton {
  background-color: #007bff; /* Blue for create */
  color: #ffffff;
}

.createButton:hover {
  background-color: #0056b3;
}

.editButton {
  background-color: #28a745; /* Green for edit */
  color: #ffffff;
}

.editButton:hover {
  background-color: #218838;
}

.submitButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.submitButton:active {
  transform: translateY(1px);
}

.currencyInput {
  position: relative;
}

.currencySymbol {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #555;
  font-size: 16px;
}

.currencyControl {
  padding-left: 30px;
}

@media (max-width: 600px) {
  .formContainer {
    width: 95%;
    padding: 1.5rem;
  }
}

.cleanersList {
  margin-top: 20px;
  margin-bottom: 25px;
  padding: 15px;
  border: 1px #ccc;
  border-radius: 6px;
  background-color: #ffffff;
}

.cleanersList h3 {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #444;
}

.cleanersList ul {
  list-style-type: none;
  padding: 0;
}

.cleanersList li {
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
}

.cleanersList li:last-child {
  border-bottom: none;
}

.infoIcon {
  margin-left: 8px;
  font-size: 14px;
  color: #888;
  cursor: pointer;
  transition: color 0.3s ease;
  vertical-align: middle;
}

.infoIcon:hover {
  color: #555;
}

.noCleanersMessage {
  margin-top: 20px;
  margin-bottom: 25px;
  padding: 20px;
  border: 1px solid #f0ad4e;
  border-radius: 8px;
  background-color: #fff3cd;
  color: #856404;
  text-align: center;
}

.noCleanersTitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.noCleanersText {
  font-size: 16px;
  margin-bottom: 15px;
}

.propertyLink {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  text-decoration: none;
  border-radius: 6px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.propertyLink:hover {
  background-color: #0056b3;
}
