.modalContent {
    background: white;
    padding: 24px;
    border-radius: 8px;
    max-width: 800px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #111827;
  }
  
  .closeButton {
    background: none;
    border: none;
    color: #6B7280;
    cursor: pointer;
    padding: 4px;
    transition: color 0.2s;
  }
  
  .closeButton:hover {
    color: #111827;
  }
  
  .section {
    margin-bottom: 24px;
  }
  
  .section h3 {
    color: #374151;
    font-size: 1.1rem;
    margin-bottom: 16px;
  }
  
  .info {
    background: #F9FAFB;
    padding: 16px;
    border-radius: 6px;
  }
  
  .info p {
    margin: 8px 0;
    color: #4B5563;
  }
  
  .info strong {
    color: #374151;
  }
  
  .tableWrapper {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .table th,
  .table td {
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid #E5E7EB;
  }
  
  .table th {
    background: #F9FAFB;
    color: #374151;
    font-weight: 600;
  }
  
  .table td {
    color: #4B5563;
  }
  
  .loading {
    text-align: center;
    padding: 24px;
    color: #6B7280;
  }
  
  .error {
    text-align: center;
    padding: 24px;
    color: #DC2626;
  }