.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease-in-out;
}

.modal {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.2s ease-in-out;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #e5e7eb;
}

.modalHeader h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #111827;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  color: #6b7280;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
}

.closeButton:hover {
  background-color: #f3f4f6;
}

.modalContent {
  padding: 24px;
}

.modalFooter {
  padding: 16px 24px;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.formGroup {
  margin-bottom: 20px;
}

.formGroup label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 8px;
}

.input {
  width: 100%;
  padding: 10px 14px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 14px;
  color: #374151;
}

.tooltip {
  margin-left: 6px;
  color: #6b7280;
  cursor: help;
}

.inputError {
  border-color: #ef4444;
}

.errorText {
  color: #ef4444;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}

.previewBox {
  margin-top: 20px;
  padding: 16px;
  background-color: #f3f4f6;
  border-radius: 6px;
  border: 1px solid #e5e7eb;
}

.previewBox h4 {
  margin: 0 0 12px 0;
  font-size: 14px;
  font-weight: 600;
  color: #374151;
}

.previewBox p {
  margin: 4px 0;
  color: #6b7280;
}

.primaryButton,
.secondaryButton {
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.primaryButton {
  background-color: #2563eb;
  border: none;
  color: white;
}

.secondaryButton {
  background-color: white;
  border: 1px solid #e5e7eb;
  color: #374151;
}

.primaryButton:hover {
  background-color: #1d4ed8;
}

.secondaryButton:hover {
  background-color: #f9fafb;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@media (max-width: 640px) {
  .modal {
    width: 95%;
    margin: 16px;
  }

  .modalContent {
    padding: 16px;
  }

  .modalHeader,
  .modalFooter {
    padding: 12px 16px;
  }
}
