/* Hero Section */
.hero {
  padding: 5rem 2rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
}

.heroContent {
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
  padding: 0;
}

.heroTitle {
  font-size: 2.8rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  word-wrap: break-word;
}

.heroSubtitle {
  font-size: 1.25rem;
  color: #34495e;
  margin-bottom: 2rem;
  line-height: 1.5;
  background: transparent;
  padding: 0 1rem;
  word-wrap: break-word;
}

/* Content Sections */
.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 1rem;
  padding-top: 1.5rem;
}

.section {
  margin: 3rem 0;
  padding: 2rem;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.sectionTitle {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.sectionContent {
  font-size: 1.1rem;
  color: #34495e;
  line-height: 1.6;
}

/* Steps List */
.stepsList {
  counter-reset: steps;
  padding-left: 0;
  margin: 2rem 0;
}

.stepItem {
  position: relative;
  list-style: none;
  margin-bottom: 1.5rem;
  padding-left: 3.5rem;
  counter-increment: steps;
}

.stepItem:before {
  content: counter(steps);
  position: absolute;
  left: 0;
  top: 0;
  width: 35px;
  height: 35px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.stepTitle {
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

/* Call to Action */
.ctaSection {
  text-align: center;
  background-color: #f1f8ff;
  padding: 3rem;
  border-radius: 10px;
  margin: 3rem 0;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.ctaTitle {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.ctaDescription {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: white;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Contact Section */
.contactSection {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 4rem;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.contactEmail {
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.contactEmail:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    padding: 3rem 1rem;
  }
  
  .heroTitle {
    font-size: 2rem;
  }
  
  .heroSubtitle {
    font-size: 1.1rem;
  }
  
  .section {
    padding: 1.5rem;
    margin: 2rem 0;
  }
  
  .sectionTitle {
    font-size: 1.6rem;
  }
  
  .buttonContainer {
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .contactSection {
    margin-bottom: 80px;
  }
}
