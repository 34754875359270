/* Test.module.css */
.testContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #f4f4f4;
  color: #333;
  font-family: 'Arial', sans-serif;
  overflow: auto;
  position: relative;
  padding: 20px;
}

.testTitle {
  font-size: 2.5em;
  margin-bottom: 1em;
  color: #333;
  text-transform: uppercase;
}

.errorMessage {
  color: #e74c3c;
  background: #f9ecec;
  padding: 0.5em;
  border-radius: 5px;
  font-weight: bold;
}

.jobContainer {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.jobItem {
  position: relative;
  background: #fff;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-size: 1.2em;
  margin: 0.5em 0;
  transition: transform 0.3s ease, background 0.3s ease;
}

.jobItem:hover {
  transform: scale(1.05);
  background: #f0f0f0;
}

.randomElement {
  display: none; /* Hide random elements in professional mode */
}
