.card {
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  max-width: 1000px;
  margin: 20px auto;
}

.card:hover {
  transform: translateY(-2px);
}

.overallStats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.statIcon {
  font-size: 24px;
  margin-bottom: 4px;
}

.statLabel {
  font-size: 0.875rem;
  color: #666;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.statValue {
  font-size: 1.75rem;
  font-weight: 700;
  color: #0077cc;
}

.sectionTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #222;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.titleIcon {
  font-size: 1.5rem;
}

.statsSection {
  margin-bottom: 40px;
}

.statsSection:last-child {
  margin-bottom: 0;
}

h1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.statsCard {
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  border: 1px solid #e0e0e0;
}

.statsCardHeader {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 16px;
}

.headerIcon {
  font-size: 24px;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.filterContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 16px 0 24px;
  padding: 4px;
  background: #f5f5f5;
  border-radius: 8px;
  width: 100%;
  overflow-x: hidden;
}

.filterButton {
  padding: 8px 12px;
  white-space: nowrap;
  flex-shrink: 0;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: #666;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.filterButton:hover {
  background: #e0e0e0;
  color: #333;
}

.filterButton.active {
  background: white;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filterCount {
  background: #e0e0e0;
  color: #666;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  min-width: 24px;
  text-align: center;
}

.filterButton.active .filterCount {
  background: #0077cc;
  color: white;
}

.price {
  display: block;
  font-weight: 600;
}

.statsList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #f8f9fa;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.listItem:hover {
  background-color: #f0f2f5;
}

.listHeader {
  display: flex;
  align-items: center;
  gap: 12px;
}

.avatarCircle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0077cc;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.2rem;
}

.propertyIcon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}

.listItemTitle {
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
  margin: 0;
}

.listStats {
  display: flex;
  align-items: center;
  gap: 24px;
}

.statBadge {
  background-color: white;
  padding: 8px 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100px;
}

.badgeLabel {
  font-size: 0.75rem;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.badgeValue {
  font-weight: 600;
  color: #0077cc;
}

@media (max-width: 768px) {
  .card {
    padding: 20px;
    margin: 16px;
  }

  .overallStats {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .statsGrid {
    grid-template-columns: 1fr;
  }

  .statValue {
    font-size: 1.5rem;
  }

  .sectionTitle {
    font-size: 1.25rem;
  }

  .listItem {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .listStats {
    width: 100%;
    justify-content: space-between;
  }

  .filterContainer {
    margin: 12px 0 20px;
    padding: 3px;
    gap: 0.25rem;
  }

  .filterButton {
    padding: 6px 10px;
    font-size: 0.875rem;
  }

  .filterCount {
    padding: 1px 6px;
    font-size: 0.7rem;
    min-width: 20px;
  }
}

.inlineSummary {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
  padding: 16px;
  background-color: #e9f5ff;
  border-radius: 8px;
  font-size: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.inlineStat {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.inlineStat:hover {
  transform: translateY(-2px);
  background-color: #d0e7ff;
}

.inlineLabel {
  color: #555;
  font-weight: 500;
}

.inlineValue {
  font-weight: 700;
  color: #0056b3;
  text-decoration: underline;
}

.headerContent {
  flex: 1;
}

.headerContent h2 {
  margin: 0;
  margin-bottom: 4px;
}

.totalPrice {
  font-size: 0.9rem;
  color: #666;
  display: flex;
  gap: 6px;
  align-items: center;
}

.totalPrice .price {
  color: #0077cc;
  font-weight: 600;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  border-radius: 12px;
  width: 100%;
  max-width: 1000px;
  margin: 20px;
  max-height: 90vh;
  overflow-y: auto;
}

/* Filter Button */
.filterButton {
  background-color: #f0f0f0;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filterButton:hover {
  background-color: #e0e0e0;
}


/* Filter Modal specific styles */
.filterModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.filterModalContent {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  max-width: 90%;
  width: 500px;
  max-height: 80vh;
  overflow-y: auto;
}

.filterModalContent h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 1.5rem;
}

.filtersContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
  padding: 0 8px;
}

.filterItem label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #444;
}

@media (max-width: 768px) {
  .filterModalContent {
    width: 95%;
    margin: 20px;
    padding: 16px;
  }
}

/* Base styling for all stat boxes */
.statBox {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  text-align: center;
  border: 1px solid #e9ecef;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

/* Clickable stat box */
.statBox.clickable {
  cursor: pointer;
}

.statBox.clickable:hover {
  background-color: #f0f8ff; /* Light blue background on hover */
  transform: translateY(-2px);
}

.statBox.nonClickable {
  cursor: default;
  background-color: #f3f1f1;
}

.activeFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}

.filterBadge {
  background-color: #e0e0e0;
  color: #333;
  padding: 4px 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.875rem;
  font-weight: 500;
}

.removeFilter {
  cursor: pointer;
  color: #ff0000;
  font-weight: bold;
  margin-left: 4px;
  transition: color 0.2s ease;
}

.removeFilter:hover {
  color: #cc0000;
}