.container {
  padding: 20px;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
}

.content {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.title {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
}

.description {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 20px;
  text-align: center;
}

.propertyContainer {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f5f5f5;
}

.propertyTitle {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.stats {
  margin-bottom: 10px;
}

.stats p {
  margin: 5px 0;
  font-size: 1.1em;
}

.totalContainer {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f5f5f5;
}

.backBtn {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
}

.backBtn:hover {
  background-color: #0056b3;
}
