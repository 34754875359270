.propertyCardsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
}

.propertyCard {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.propertyCard:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.cardTitle {
  margin: 0 0 16px 0;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
}

.cardContent {
  margin: 12px 0 0;
  color: #666;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Show only 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
  max-height: 2.8em; /* 2 lines * 1.4 line-height */
}

.popupModal {
  position: fixed;
  top: 5%;
  bottom: 70px; /* Adjust this value to match your mobile navbar height */
  left: 5%;
  right: 5%;
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  max-height: calc(90vh - 70px); /* Adjust 70px to match your mobile navbar height */
  overflow: hidden;
}

.modalOverlay {
  background-color: rgba(0, 0, 0, 0.8); /* Ensuring a very dark overlay */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; /* Ensure it's above everything else */
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.modalTitle {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  color: #333;
  text-align: center; /* Center the title */
  flex-grow: 1;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
}

.modalBody {
  margin-top: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Distribute space between elements */
  overflow-y: auto; /* Allow scrolling for modal content if needed */
  gap: 20px; /* Space between elements */
}

.modalFooter {
  display: flex;
  justify-content: space-between; /* Center the buttons horizontally */
  padding-top: 10px;
  gap: 8px;
  margin-top: auto; /* Pushes the footer to the bottom of the modal */
}



.description-textarea {
  flex: 2; /* Take up twice the space compared to the inputs section */
  width: 100%;
  resize: vertical; /* Allow vertical resizing */
  margin-bottom: 20px; /* Space between textarea and other elements */
  max-height: calc(60vh); /* Maximum height to prevent overflow */
}

.inputSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.inputRow {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  margin-bottom: 10px; /* Space between rows */
  width: 100%; /* Make each row take the full width */
}

.labelField {
  width: 250px; /* Fixed width for labels, ensuring alignment */
  margin-right: 10px; /* Space between label and input */
}

.smallInput {
  width: 100px; /* Fixed width for input fields */
}

.trashIconButton {
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

.trashIcon {
  color: #6c757d; /* Neutral gray color */
  font-size: 16px; /* Slightly larger size for better visibility */
  margin: 0; /* Remove any default margin */
}

.inputGroupLimited {
  max-width: 400px; /* Set the maximum width for the input group */
  width: 100%; /* Allow it to take up the full width up to the max-width */
  margin-bottom: 10px; /* Optional: spacing between elements */
}

.centeredTitleContainer {
  text-align: center;
  padding: 20px 0;
}

.centeredTitle {
  display: inline-block;
  margin: 0;
}

.centeredTitle button {
  margin-left: 10px;
  vertical-align: middle;
}

.imageContainer {
  position: relative;
  width: 70%;
  padding-top: 28%;
  margin: 12px auto;
  border-radius: 8px;
  overflow: hidden;
  max-height: 120px;
}

.propertyImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.imagePlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #999;
  gap: 6px;
}

.imagePlaceholder .icon {
  font-size: 20px;
}

.icon {
  color: #666;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.statsContainer {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  padding: 12px;
  background: #f8f9fa;
  border-radius: 6px;
}

.statItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 4px;
}

.statValue {
  font-weight: 600;
  color: #333;
  font-size: 1.1em;
}

.teamContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}

.teamGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.teamMember {
  background: #f0f0f0;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.managerBadge {
  background: #e3f2fd;
  color: #1976d2;
}

.cleanerBadge {
  background: #e8f5e9;
  color: #2e7d32;
}

.timeDisplay {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: #f5f5f5;
  border-radius: 6px;
  font-size: 14px;
  margin: 12px 0;
}

.statusIndicator {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  margin-top: 12px;
  padding: 4px 0;
}

.statusDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.statusActive {
  background-color: #4caf50;
}

.statusInactive {
  background-color: #f44336;
}

.modalImage {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
  border-radius: 8px;
  background-color: #f5f5f5;
}

.mapContainer {
  position: relative;
  width: 70%;
  padding-top: 32%;
  margin: 12px auto;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #eee;
  max-height: 120px;
}

/* Add this to ensure the map fills the container properly */
.mapContainer > div {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.locationAddress {
  text-align: center;
  margin-top: 8px;
  color: #666;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

/* Hide map controls in preview mode */
.mapContainer :global(.leaflet-control-container) {
  display: none;
}

.priceDisplay {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  background: #f8f9fa;
  border-radius: 6px;
  margin: 12px 0;
}

.currencyIcon {
  color: #666;
  font-size: 1.1em;
}

.priceValue {
  font-weight: 600;
  color: #333;
  font-size: 1.2em;
}

.currencyCode {
  color: #666;
  font-size: 0.9em;
}
