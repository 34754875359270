.container {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.pageTitle {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #222;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.cardLink {
  text-decoration: none;
  color: inherit;
  display: block;
}

.card {
  border-radius: 12px;
  overflow: hidden;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 100%;
}

.cardLink:hover .card {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.card:hover {
  transform: none;
  box-shadow: none;
}

.imageContainer {
  position: relative;
  padding-top: 66.67%; /* 3:2 aspect ratio */
}

.propertyImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  padding: 1.75rem;
}

.title {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0 0 1.25rem 0;
  color: #222;
  line-height: 1.3;
}

.infoItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  padding: 0.5rem 0;
}

.icon {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
  margin-top: 0.2rem;
  color: #666;
}

.address {
  font-size: 0.95rem;
  color: #666;
  line-height: 1.5;
  margin: 0;
}

.schedule, .pricing {
  flex-grow: 1;
}

.label {
  display: block;
  font-size: 0.85rem;
  color: #666;
  margin-bottom: 0.25rem;
}

.time {
  font-size: 0.95rem;
  color: #222;
  font-weight: 500;
}

.amount {
  display: block;
  font-size: 1.1rem;
  color: #222;
  font-weight: 600;
}

.descriptionContainer {
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid #eee;
}

.description {
  font-size: 0.95rem;
  color: #666;
  line-height: 1.6;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.actions {
  padding: 1rem 1.5rem;
  border-top: 1px solid #eee;
}

.editButton {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
}

.editButton:hover {
  background-color: #0056b3;
}

.addPropertyContainer {
  text-align: center;
  margin-top: 2rem;
}

.addButton {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-size: 1rem;
  transition: background-color 0.2s ease;
}

.addButton:hover {
  background-color: #218838;
}

@media (min-width: 1200px) {
  .grid {
    grid-template-columns: repeat(3, minmax(350px, 1fr));
  }
  
  .content {
    padding: 1.75rem;
  }
  
  .title {
    font-size: 1.4rem;
  }
  
  .address, .schedule, .description {
    font-size: 1rem;
  }
  
  .pricing {
    font-size: 1.1rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .grid {
    grid-template-columns: 1fr;
  }
  
  .container {
    padding: 1rem;
  }
  
  .content {
    padding: 1.25rem;
  }
  
  .title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .infoItem {
    margin-bottom: 0.75rem;
  }
  
  .amount {
    font-size: 1rem;
  }
}

/* Add these styles for the empty state */
.noPropertiesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
  margin: 20px 0;
}

.noPropertiesContent {
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 2.5rem;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #eee;
}

.noPropertiesContent h2 {
  margin-bottom: 1rem;
  color: #333;
  font-size: 24px;
}

.noPropertiesContent p {
  margin-bottom: 1.5rem;
  color: #666;
  font-size: 16px;
  line-height: 1.5;
}

.createPropertyButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

.createPropertyButton:hover {
  background-color: #0056b3;
}
