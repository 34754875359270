.card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  max-width: 1000px; /* Allow more width on larger screens */
  margin: 20px auto;
  transition: all 0.3s ease;
}

.responsiveCard {
  max-width: 600px;
  margin: 0 auto;
}

.dateNavigation {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: nowrap; /* Prevent wrapping */
}

.button {
  background-color: #0077cc;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1.5rem; /* Increase icon size */
}

.button:hover {
  background-color: #005fa3; /* Darker blue on hover */
  transform: scale(1.1);
}

.monthRange {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  flex-grow: 1;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 15px;
  color: #333;
  font-weight: bold;
}

.dateInputContainer {
  margin-top: 20px;
  padding: 10px;
  border-top: 1px solid #eee;
}

.dateInput {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: nowrap;
  width: 100%;
}

.dateField {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  flex: 1;
  min-width: 0;
}

.dateField label {
  font-size: 1rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 5px;
}

.datePicker {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  max-width: none;
  transition: border-color 0.3s ease;
  font-size: 1.4rem;
}

.datePicker:focus {
  border-color: #0077cc;
  outline: none;
}

/* Mobile optimization */
@media (max-width: 600px) {
  .card {
    padding: 15px 10px; /* Increase vertical padding slightly */
  }

  .dateNavigation {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px; /* Increase gap slightly */
    margin-bottom: 15px; /* Reduce bottom margin */
  }

  .button {
    width: 40px; /* Increase button size */
    height: 40px;
    min-width: 40px;
    font-size: 1.2rem; /* Adjust icon size for mobile */
  }

  .monthRange {
    font-size: 1.6rem;
    padding: 0 5px;
  }

  .description {
    font-size: 1.2rem;
  }

  .dateField label {
    font-size: 1.2rem;
  }

  .dateInput {
    flex-direction: row;
    gap: 0.5rem;
  }

  .datePicker {
    padding: 8px;
    font-size: 1.1rem;
  }
}
