/* Add these classes for event colors based on status */
.event {
  /* Base event styling if needed */
}

.open {
  background-color: #ffc107 !important;
}

.applied {
  background-color: #17a2b8 !important;
}

.accepted {
  background-color: #28a745 !important;
}

.completed {
  background-color: #6c757d !important;
}

.default {
  background-color: #007bff !important;
}

/* This ensures our styles take precedence over react-big-calendar styles */
.calendarView :global(.rbc-event) {
  background-color: transparent;
}

.fullPageModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullPageModalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  height: 90%;
  overflow-y: auto;
}
