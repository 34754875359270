.siteWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 0;
  @media (max-width: 768px) {
    margin-bottom: 60px; /* Space for mobile navbar only on small screens */
  }
}
