.container {
  padding: 24px;
  width: 90%;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.stepIndicator {
  font-size: 14px;
  color: #6b7280;
  font-weight: 500;
}

.formContainer {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 90%;
}

.step {
  margin-bottom: 24px;
  width: 90%;
}

.step h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #374151;
}

.formGroup {
  margin-bottom: 20px;
}

.formGroup label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 8px;
}

.select {
  width: 300px;
  padding: 10px 14px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 14px;
  color: #374151;
}

.dateInputs {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 600px;
}

.dateInput {
  flex: 1;
  padding: 10px 14px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 14px;
  color: #374151;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #e5e7eb;
}

.backButton,
.nextButton,
.submitButton {
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.backButton {
  background-color: white;
  border: 1px solid #e5e7eb;
  color: #374151;
}

.nextButton,
.submitButton {
  background-color: #2563eb;
  border: none;
  color: white;
}

.backButton:hover {
  background-color: #f9fafb;
}

.nextButton:hover,
.submitButton:hover {
  background-color: #1d4ed8;
}

.jobList {
  margin-top: 24px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}

.jobListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
}

.jobListHeader h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #374151;
}

.jobListHeader span {
  font-size: 14px;
  color: #6b7280;
}

.jobItem {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  cursor: pointer;
  transition: background-color 0.2s;
}

.jobItem:last-child {
  border-bottom: none;
}

.jobItem:hover {
  background-color: #f9fafb;
}

.jobItem.selected {
  background-color: #f3f4f6;
}

.jobCheckbox {
  margin-right: 16px;
}

.jobDetails {
  flex: 1;
}

.jobDetails h4 {
  margin: 0 0 8px 0;
  font-size: 14px;
  font-weight: 600;
  color: #111827;
}

.jobInfo {
  display: flex;
  gap: 16px;
  font-size: 14px;
  color: #6b7280;
}

.jobInfo span {
  display: flex;
  align-items: center;
}

.input {
  width: 300px;
  padding: 10px 14px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 14px;
  color: #374151;
}

.payoutTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}

.payoutTable th,
.payoutTable td {
  padding: 12px 16px;
  border-bottom: 1px solid #e5e7eb;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.payoutTable th {
  text-align: left;
  background-color: #f9fafb;
  color: #374151;
  font-weight: 500;
}

.payoutTable th:nth-child(1),
.payoutTable td:nth-child(1) {
  width: 25%;
}

.payoutTable th:nth-child(2),
.payoutTable td:nth-child(2) {
  width: 50%;
}

.payoutTable th:nth-child(3),
.payoutTable td:nth-child(3) {
  width: 25%;
  text-align: right;
}

.subtotalRow {
  background-color: #f9fafb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-top: 2px solid #e5e7eb;
  margin-bottom: 24px;
  font-weight: 600;
}

.bonusDetails {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  font-size: 14px;
  color: #6b7280;
}

.bonusDetails span {
  background: #f3f4f6;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
}

.payoutActions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.actionButton {
  padding: 6px;
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  border-radius: 4px;
}

.actionButton:hover {
  background-color: #f3f4f6;
  color: #374151;
}

.cleanerHeader {
  margin-bottom: 24px;
}

.selectedCleaner {
  margin-top: 8px;
  font-size: 16px;
  color: #6b7280;
}

.selectedCleaner span {
  color: #111827;
  font-weight: 500;
}

.sectionCard {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
}

.sectionTitle {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.sectionNumber {
  background: #2563eb;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-right: 12px;
}

.sectionHeading {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.sectionHelp {
  color: #6b7280;
  font-size: 14px;
  margin: 4px 0 20px 36px;
}

.summaryCard {
  background: #f8fafc;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 24px;
  margin-top: 32px;
}

.summaryTitle {
  font-size: 20px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 20px;
}

.summaryRow {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #e5e7eb;
}


.summaryLabel {
  color: #374151;
  font-weight: 500;
}

.summaryAmount {
  font-family: system-ui;
  font-weight: 600;
  color: #374151;
}

.grandTotal {
  font-size: 18px;
  color: #2563eb;
}

.select[multiple] {
  height: auto;
  min-height: 120px;
}

.helpText {
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: #6b7280;
}

.reactSelect {
  width: 100%;
  max-width: 600px;
}

.reactSelect :global(.select__control) {
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  min-height: 42px;
}

.reactSelect :global(.select__control--is-focused) {
  border-color: #2563eb;
  box-shadow: 0 0 0 1px #2563eb;
}

.reactSelect :global(.select__menu) {
  border: 1px solid #e5e7eb;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.reactSelect :global(.select__option--is-focused) {
  background-color: #f3f4f6;
}

.reactSelect :global(.select__option--is-selected) {
  background-color: #2563eb;
}

.reactSelect :global(.select__multi-value) {
  background-color: #f3f4f6;
}

.reactSelect :global(.select__multi-value__remove:hover) {
  background-color: #ef4444;
  color: white;
}

.inputGroup {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  max-width: 150px;
}

.inputGroup input {
  padding-left: 30px;
  width: 100%;
}

.currencyIndicator {
  position: absolute;
  left: 12px;
  color: #6b7280;
  font-size: 14px;
  pointer-events: none;
}

@media (max-width: 640px) {
  .container {
    padding: 16px;
    width: 100%;
  }

  .formContainer {
    padding: 16px;
    width: 100%;
  }

  .step {
    width: 100%;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .select,
  .dateInputs {
    width: 100%;
  }

  .dateInputs {
    flex-direction: column;
    gap: 8px;
  }

  .dateInput {
    width: 100%;
  }

  .jobInfo {
    flex-direction: column;
    gap: 4px;
  }

  .buttons {
    flex-direction: column-reverse;
    gap: 8px;
  }

  .backButton,
  .nextButton,
  .submitButton {
    width: 100%;
    padding: 12px;
  }

  .jobItem {
    padding: 12px;
  }

  .jobDetails h4 {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .jobInfo {
    font-size: 13px;
  }

  .reviewTable {
    display: block;
    overflow-x: auto;
  }
  
  .payoutItem {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  
  .payoutAmount {
    align-self: flex-end;
  }
  
  .totalRow {
    padding: 12px 8px;
  }

  .sectionCard {
    padding: 16px;
  }

  .bonusDetails {
    flex-direction: column;
    gap: 4px;
  }

  .payoutTable {
    font-size: 14px;
  }

  .payoutTable th,
  .payoutTable td {
    padding: 8px 12px;
  }

  .payoutTable {
    display: table;
    width: 100%;
  }

  .payoutTable tbody {
    display: table-row-group;
  }

  .payoutTable tr {
    display: table-row;
    padding: 0;
    border-bottom: 1px solid #e5e7eb;
  }

  .payoutTable td {
    display: table-cell;
    padding: 12px 8px;
    border-bottom: 1px solid #e5e7eb;
    vertical-align: middle;
  }

  .payoutTable td:before {
    display: none;
  }

  .payoutTable thead {
    display: table-header-group;
  }

  .payoutTable th:nth-child(1),
  .payoutTable td:nth-child(1) {
    width: 30%;
  }

  .payoutTable th:nth-child(2),
  .payoutTable td:nth-child(2) {
    width: 40%;
  }

  .payoutTable th:nth-child(3),
  .payoutTable td:nth-child(3) {
    width: 30%;
    text-align: right;
  }

  .payoutTable {
    font-size: 14px;
  }

  .sectionCard {
    overflow-x: auto;
  }

  .payoutActions {
    justify-content: flex-start;
  }

  .inputGroup {
    max-width: 200px;
  }

  /* Mobile styles for payoutTable */
  .payoutTable {
    display: table;
    width: 100%;
  }

  .payoutTable tbody {
    display: table-row-group;
  }

  .payoutTable tr {
    display: table-row;
  }

  .payoutTable td {
    display: table-cell;
    padding: 12px 8px;
    border-bottom: 1px solid #e5e7eb;
    vertical-align: middle;
  }

}

/* Additional Payouts Table - Desktop */
.additionalPayoutsTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.additionalPayoutsTable th,
.additionalPayoutsTable td {
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
}

.additionalPayoutsTable th {
  text-align: left;
  background-color: #f9fafb;
  color: #374151;
  font-weight: 500;
}

.additionalPayoutsTable td {
  vertical-align: middle;
}

.additionalPayoutsSubtotal {
  padding-right: 130px;
}

/* Type cell with delete button */
.typeCell {
  display: flex;
  align-items: center;
  gap: 12px;
}

.deleteButton {
  padding: 6px;
  background: none;
  border: none;
  color: #ef4444;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.deleteButton:hover {
  background-color: #fee2e2;
}

/* Rating bonus styles */
.ratingBonusRow {
  cursor: pointer;
}

.ratingBonusRow:hover {
  background-color: #f9fafb;
}

.ratingBonusType {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #374151;
}

.editIcon {
  color: #6b7280;
}

.ratingBonusRow:hover .editIcon {
  color: #2563eb;
}

.ratingBonusDetails {
  color: #6b7280;
  font-size: 14px;
}

/* Mobile styles */
@media (max-width: 640px) {
  .additionalPayoutsTable thead {
    display: none;
  }

  .additionalPayoutsTable,
  .additionalPayoutsTable tbody,
  .additionalPayoutsTable tr {
    display: block;
    width: 100%;
  }

  .additionalPayoutsTable tr {
    padding: 16px;
    border-bottom: 1px solid #e5e7eb;
  }

  .additionalPayoutsTable td {
    display: block;
    padding: 4px 0;
    border: none;
    width: 100%;
  }

  /* Type cell (first td) */
  .additionalPayoutsTable tr > td:first-child {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    width: 100%;
  }

  /* Details/Description cell (second td) */
  .additionalPayoutsTable tr > td:nth-child(2) {
    margin-bottom: 12px;
    padding-left: 36px;
  }

  /* Amount cell (last td) */
  .additionalPayoutsTable tr > td:last-child {
    float: none;
    margin-top: 0;
    padding-left: 36px;
  }

  /* Add labels for mobile */
  .additionalPayoutsTable tr > td:nth-child(2)::before {
    display: block;
    font-size: 12px;
    color: #6b7280;
    margin-bottom: 4px;
  }

  .additionalPayoutsTable tr > td:last-child::before {
    display: block;
    font-size: 12px;
    color: #6b7280;
    margin-bottom: 4px;
  }

  /* Adjust input widths for mobile */
  .additionalPayoutsTable .input,
  .additionalPayoutsTable .inputGroup {
    width: 100%;
    max-width: 100%;
  }

  .additionalPayoutsSubtotal {
    padding-right: 10px;
  }

}
