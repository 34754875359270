.container {
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  max-width: 1000px;
  margin: 20px auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.actions {
  display: flex;
  gap: 12px;
}

.createButton, .exportButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.createButton {
  background-color: #2563eb;
  color: white;
  border: none;
}

.exportButton {
  background-color: white;
  color: #4b5563;
  border: 1px solid #e5e7eb;
}

.periodSelector {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}

.statsCards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 32px;
}

.statCard {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.tableContainer {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.mobileView {
  display: none;
}

/* Add/update these table styles */
.payoutsTable {
  width: 100%;
  border-collapse: collapse;
}

.payoutsTable th {
  text-align: left;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #6b7280;
  border-bottom: 1px solid #e5e7eb;
}

.payoutsTable td {
  padding: 16px;
  font-size: 14px;
  border-bottom: 1px solid #e5e7eb;
}

.tableRow {
  cursor: pointer;
}

.tableRow:hover {
  background-color: #f9fafb;
}

.status {
  display: flex;
  align-items: center;
  gap: 6px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .container {
    padding: 16px;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .actions {
    width: 100%;
  }

  .statsCards {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  .desktopView {
    display: none;
  }

  .mobileView {
    display: block;
  }

  .mobileTableRow {
    padding: 16px;
    border-bottom: 1px solid #e5e7eb;
    cursor: pointer;
  }

  .mobileTableRow:hover {
    background-color: #f9fafb;
  }

  .mobileTableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    font-weight: 500;
  }

  .mobileTableDetails {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    font-size: 14px;
  }

  .mobileTableDetails label {
    color: #6b7280;
    display: block;
    margin-bottom: 4px;
  }
}

@media (max-width: 480px) {
  .statsCards {
    grid-template-columns: 1fr;
  }

  .actions {
    flex-direction: column-reverse;
    gap: 8px;
  }

  .createButton, .exportButton {
    width: 100%;
    justify-content: center;
  }
}

.periodTypeButtons {
  display: flex;
  gap: 1px;
  background-color: #f3f4f6;
  padding: 3px;
  border-radius: 8px;
  margin-bottom: 16px;
  width: fit-content;
}

.periodTypeButton {
  padding: 8px 16px;
  border: none;
  background: none;
  font-size: 14px;
  cursor: pointer;
  border-radius: 6px;
  color: #6b7280;
  transition: all 0.2s;
  font-weight: 500;
}

.periodTypeButton:hover:not(.active) {
  color: #111827;
}

.periodTypeButton.active {
  background-color: white;
  color: #111827;
  font-weight: 600;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.periodSelect {
  padding: 10px 14px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  min-width: 200px;
  color: #374151;
}

.dateRangeInputs {
  display: flex;
  align-items: center;
  gap: 12px;
}

.dateInput {
  padding: 10px 14px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  color: #374151;
  width: 180px;
}

.dateSeparator {
  color: #6b7280;
  font-size: 14px;
  font-weight: 500;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
