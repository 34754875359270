.taxPage {
  padding: 20px;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
}

.taxHeader {
  text-align: center;
  margin-bottom: 20px;
}

.taxHeader h1 {
  font-size: 2.5em;
  margin: 0;
}

.taxHeader h2 {
  font-size: 1.5em;
  color: #666;
  margin: 0;
}

.uploadBtn {
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.uploadBtn:hover {
  background-color: #0056b3;
}

.taxSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.taxYear {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
}

.taxYear h3 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.summaryTotal {
  margin-bottom: 20px;
}

.summaryTotal h4 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.summaryItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.summaryItem .label {
  font-weight: bold;
}

.summaryItem .value {
  color: #333;
}

.detailBtn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.detailBtn:hover {
  background-color: #218838;
}
