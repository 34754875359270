.jobDetailsContainer {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.jobHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  position: relative;
}

.jobTitle {
  font-size: 24px;
  color: #333;
  margin: 0;
  flex-grow: 1;
  text-align: left;
}

.propertyTitleLink {
  cursor: pointer;
  color: #0066cc;
  text-decoration: none;
}

.propertyTitleLink:hover {
  text-decoration: underline;
}

.actionButtons {
  display: flex;
  gap: 10px;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.actionButton:hover {
  background-color: #f5f5f5;
}

.actionButton svg {
  margin-right: 2px;
}

.deleteAction {
  color: #d32f2f;
  border-color: #ffcdd2;
}

.deleteAction:hover {
  background-color: #ffebee;
}

.headerDivider {
  height: 1px;
  background-color: #e0e0e0;
  margin: 0 0 20px 0;
}

.section {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.section h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

.section p {
  margin: 5px 0;
  font-size: 14px;
}

.mapPlaceholder {
  height: 200px;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  color: #777;
  margin-top: 10px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.closeButton,
.acceptButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.closeButton {
  background-color: #f0f0f0;
  color: #333;
}

.closeButton:hover {
  background-color: #e0e0e0;
}

.acceptButton {
  background-color: #4CAF50;
  color: white;
}

.acceptButton:hover {
  background-color: #45a049;
}

.deleteButton, .editButton {
  display: none;
}

.reopenButton {
  background-color: #ff9800;
  color: white;
}

.reopenButton:hover {
  background-color: #e68a00;
}

.errorMessage {
  color: red;
  background-color: #ffe6e6;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.errorPopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.errorPopup {
  background-color: #ffe6e6;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  text-align: center;
}

.closeErrorButton {
  margin-top: 10px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s ease;
}

.closeErrorButton:hover {
  background-color: #e0e0e0;
}

/* Location Section - Updated */
.locationSection {
  margin-bottom: 2rem;
}

.address {
  background: var(--neutral-100);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: center;
}

.mapContainer {
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
}

.cleanersList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cleanerItem {
  display: flex;
  align-items: center;
  padding: 4px 0;
}

.cleanerName {
  font-weight: bold;
  color: #333;
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

.cleanerEmail {
  font-size: 0.8rem;
  color: #aaa;
}
