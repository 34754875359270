.columnHeader {
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filterIcon {
  font-size: 0.9rem;
}

@media (max-width: 576px) {
  .columnHeader {
    font-size: 0.8rem;
  }

  .filterIcon {
    font-size: 0.7rem;
  }
}

/* Keep existing styles and add: */

.tabContainer {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  border: none;
  background: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.activeTab {
  border-bottom: 2px solid #000;
  font-weight: 500;
}

.desktopList {
  display: block;
}

.mobileList {
  display: none;
}

.headerRow, .jobRow {
  display: grid;
  grid-template-columns: minmax(140px, 1fr) minmax(140px, 2fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) 100px;
  gap: 8px;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
  align-items: center;
}

.headerCell {
  font-weight: 500;
  padding: 0;
}

.jobRow > div {
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.jobCard {
  padding: 20px;
  border-radius: 8px;
  background-color: #f8f9fa;
  margin-bottom: 12px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.jobCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Add new styles for card content */
.jobStatus {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 12px;
  background-color: #ffe5e5;
  color: #cc0000;
}

.jobStatus.paid {
  background-color: #e6ffe6;
  color: #006600;
}

.propertyName {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
}

.jobDetail {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #666;
}

.jobDetail span:first-child {
  font-weight: 500;
  min-width: 100px;
  color: #444;
}

.price {
  font-size: 1.125rem;
  font-weight: 600;
  color: #0077cc;
  margin: 16px 0;
}

.detailsButton {
  width: 100%;
  padding: 12px;
  border: 1px solid #0077cc;
  border-radius: 6px;
  background: white;
  color: #0077cc;
  font-weight: 500;
  text-align: center;
  transition: all 0.2s ease;
}

.detailsButton:hover {
  background: #0077cc;
  color: white;
}

@media (max-width: 768px) {
  .desktopList {
    display: none;
  }
  
  .mobileList {
    display: block;
  }
}

/* Add card container style */
.cardContainer {
  max-width: 1000px;
  margin: 20px auto;
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  position: relative;
  padding-top: 48px; /* Add space for close button */
}

/* Update tab container to remove card-specific styles */
.tabContainer {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

/* Update media query */
@media (max-width: 768px) {
  .cardContainer {
    padding: 20px;
    margin: 16px;
  }

  .desktopList {
    display: none;
  }
  
  .mobileList {
    display: block;
  }
}

.headerControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filterButton {
  background-color: #f0f0f0;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filterButton:hover {
  background-color: #e0e0e0;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  border-radius: 8px;
  max-width: 90%;
  width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}

.filtersContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.filterItem {
  display: flex;
  flex-direction: column;
}

.filterItem label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.filterItem select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 4px;
  line-height: 1;
}

.closeButton:hover {
  color: #333;
}

@media (max-width: 768px) {
  .modalContent {
    margin: 20px;
    max-height: 90vh;
    overflow-y: auto;
  }
}

/* Add styles for react-select */
.multiSelect {
  margin-bottom: 1rem;
}

/* Style the react-select container */
.multiSelect :global(.select__control) {
  border-color: #ccc;
  box-shadow: none;
}

.multiSelect :global(.select__control:hover) {
  border-color: #999;
}

.multiSelect :global(.select__control--is-focused) {
  border-color: #007bff;
  box-shadow: 0 0 0 1px #007bff;
}

/* Style the multi-value tags */
.multiSelect :global(.select__multi-value) {
  background-color: #e8f0fe;
}

.multiSelect :global(.select__multi-value__label) {
  color: #1a73e8;
}

.multiSelect :global(.select__multi-value__remove) {
  color: #1a73e8;
}

.multiSelect :global(.select__multi-value__remove:hover) {
  background-color: #d2e3fc;
  color: #174ea6;
}

/* Style the dropdown menu */
.multiSelect :global(.select__menu) {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1100;
}

.multiSelect :global(.select__option--is-focused) {
  background-color: #f5f5f5;
}

.multiSelect :global(.select__option--is-selected) {
  background-color: #007bff;
}

/* Filter Modal specific styles */
.filterModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.filterModalContent {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  max-width: 90%;
  width: 500px;
  max-height: 80vh;
  overflow-y: auto;
}

.filterModalContent h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 1.5rem;
}

/* Update existing styles */
.filtersContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
  padding: 0 8px;
}

.filterItem label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #444;
}

@media (max-width: 768px) {
  .filterModalContent {
    width: 95%;
    margin: 20px;
    padding: 16px;
  }
}

@media (max-width: 576px) {
  .jobCard {
    padding: 16px;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .jobStatus {
    font-size: 0.75rem;
    padding: 2px 8px;
    border-radius: 12px;
    margin-bottom: 8px;
  }

  .propertyName {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .jobDetail {
    font-size: 0.875rem;
    margin-bottom: 6px;
  }

  .jobDetail span:first-child {
    min-width: 80px;
  }

  .price {
    font-size: 1rem;
    margin: 12px 0;
  }

  .detailsButton {
    padding: 10px;
    font-size: 0.875rem;
  }

  .headerRow, .jobRow {
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    padding: 10px 0;
  }

  .headerCell, .jobRow > div {
    font-size: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .modalContent {
    padding: 16px;
    margin: 10px;
    max-height: 85vh;
    overflow-y: auto;
  }
}


