:root {
  --primary-color: #2563eb;
  --primary-hover: #1d4ed8;
  --success-color: #059669;
  --neutral-100: #f1f5f9;
  --neutral-200: #e2e8f0;
  --neutral-700: #334155;
  --neutral-900: #0f172a;
  --shadow-sm: 0 1px 3px rgba(0,0,0,0.12);
  --shadow-md: 0 4px 6px rgba(0,0,0,0.1);
}

.propertyCard {
  max-width: 1200px;
  margin: 2rem auto;
  background: white;
  border-radius: 12px;
  box-shadow: var(--shadow-md);
  padding: 2rem;
}

/* Icon styles - Added/Updated */
.icon {
  width: 1.5rem;  /* 24px */
  height: 1.5rem;  /* 24px */
  color: var(--neutral-700);
  flex-shrink: 0;
}

/* Section Title - Updated */
.sectionTitle {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--neutral-200);
}

.sectionTitle h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--neutral-900);
  margin: 0;
}

/* Financial Section - Updated */
.financialSection {
  margin-bottom: 2rem;
  background: white;
}

.earningsBox {
  background: var(--neutral-100);
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
}

.amount {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--success-color);
}

/* Schedule Section - Updated */
.scheduleSection {
  margin-bottom: 2rem;
}

.timeBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  background: var(--neutral-100);
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.timeSlot {
  text-align: center;
}

.timeLabel {
  display: block;
  font-size: 0.875rem;
  color: var(--neutral-700);
  margin-bottom: 0.5rem;
}

.time {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--neutral-900);
}

.durationInfo {
  text-align: center;
  color: var(--neutral-700);
  margin-top: 1rem;
}

/* Property Details - Updated */
.detailsSection {
  margin-bottom: 2rem;
}

.detailsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
}

.detail {
  background: var(--neutral-100);
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
}

.detailLabel {
  display: block;
  font-size: 0.875rem;
  color: var(--neutral-700);
  margin-bottom: 0.5rem;
}

.detailValue {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--neutral-900);
}

/* Owner Section - Updated */
.ownerSection {
  margin-bottom: 2rem;
}

.ownerCard {
  background: var(--neutral-100);
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
}

.ownerInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.ownerInfo h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--neutral-900);
  margin: 0;
}

.ownerEmail {
  color: var(--primary-color);
  font-size: 1rem;
}

/* Location Section - Updated */
.locationSection {
  margin-bottom: 2rem;
}

.address {
  background: var(--neutral-100);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: center;
}

.mapContainer {
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
}

/* Responsive Design */
@media (max-width: 768px) {
  .propertyCard {
    margin: 0;
    border-radius: 0;
    padding: 1rem;
  }
  
  .timeBox {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .detailsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Hero Section - Updated */
.heroSection {
  position: relative;
  margin-bottom: 2rem;
  border-radius: 8px;
  overflow: hidden;
  background: var(--neutral-100);
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.propertyImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.propertyInfo {
  padding: 1.5rem;
}

.propertyInfo h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--neutral-900);
  margin: 0 0 0.5rem 0;
}

.propertyInfo p {
  color: var(--neutral-700);
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
}
